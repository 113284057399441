const toursMockData = [
    {
      id: "111111",
      name: "Fairy Meadows Adventure",
      city: "Diamer",
      address: "Fairy Meadows, Diamer, Gilgit-Baltistan, Pakistan",
      distance: 5,
      // image: "https://picsum.photos/id/101/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Experience the majestic view of Nanga Parbat!",
      price: 49.99,
      maxGroupSize: 20,
      reviews: [],
      featured: true,
    },
    {
      id: "222222",
      name: "Deosai Plains Excursion",
      city: "Skardu",
      address: "Deosai National Park, Skardu, Gilgit-Baltistan, Pakistan",
      distance: 2,
      // image: "https://picsum.photos/id/102/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Explore the land of giants, the second-highest plateau!",
      price: 39.99,
      maxGroupSize: 25,
      reviews: [],
      featured: false,
    },
    {
      id: "333333",
      name: "Hunza Valley Tour",
      city: "Hunza",
      address: "Hunza Valley, Gilgit-Baltistan, Pakistan",
      distance: 1.5,
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Discover the scenic beauty of Karimabad and historical Baltit Fort!",
      price: 59.99,
      maxGroupSize: 15,
      reviews: [],
      featured: false,
    },
    {
      id: "444444",
      name: "Rakaposhi View Point",
      city: "Nagar",
      address: "Rakaposhi View Point, Nagar, Gilgit-Baltistan, Pakistan",
      distance: 10,
      // image: "https://picsum.photos/id/104/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Witness the majestic Rakaposhi Peak!",
      price: 29.99,
      maxGroupSize: 30,
      reviews: [],
      featured: false,
    },
    {
      id: "555555",
      name: "Shangrila Resort Visit",
      city: "Skardu",
      address: "Shangrila Resort, Skardu, Gilgit-Baltistan, Pakistan",
      distance: 15,
      // image: "https://picsum.photos/id/112/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Experience the heaven on earth, Shangrila Resort!",
      price: 34.99,
      maxGroupSize: 20,
      reviews: [],
      featured: true,
    },
    {
      id: "666666",
      name: "Passu Cones Trek",
      city: "Gojal",
      address: "Passu, Gojal, Gilgit-Baltistan, Pakistan",
      distance: 8,
      // image: "https://picsum.photos/id/106/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Trek through the stunning Passu Cones!",
      price: 79.99,
      maxGroupSize: 12,
      reviews: [],
      featured: false,
    },
    {
      id: "777777",
      name: "Khunjerab Pass Journey",
      city: "Gojal",
      address: "Khunjerab Pass, Gojal, Gilgit-Baltistan, Pakistan",
      distance: 3,
      // image: "https://picsum.photos/id/113/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Travel to the highest border between Pakistan and China!",
      price: 19.99,
      maxGroupSize: 25,
      reviews: [],
      featured: false,
    },
    {
      id: "888888",
      name: "Skardu Desert",
      city: "Skardu",
      address: "Cold Desert, Skardu, Gilgit-Baltistan, Pakistan",
      distance: 2,
      // image: "https://picsum.photos/id/108/5000/3333",
      image: "https://picsum.photos/id/103/5000/3333",
      desc: "Discover the high-altitude cold desert of Skardu!",
      price: 44.99,
      maxGroupSize: 20,
      reviews: [],
      featured: true,
    },
  ];
  
  export default toursMockData;
  