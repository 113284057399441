import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { logoutUser } from "../slices/authSlice";
import { toast } from "react-toastify";
import "./NavBar.css"
import { Button } from "reactstrap";
import LogoImage from "../assets/images/logo.png"
// import LogoImage from "../assets/images/logo_image.png"


const NavBar = () => {
  const dispatch = useDispatch();
  const { cartTotalQuantity } = useSelector((state) => state.cart);
  const auth = useSelector((state) => state.auth);

  return (
    <nav className="nav-bar" style={{backgroundColor: '#FFFFFF'}}>
      <Link to="/">
        <img src={LogoImage} alt="" style={{width: "150px", height: "150px"}} />
        {/* <h2>Basecamp</h2> */}
      </Link>
      <Link to="/cart">
        <div className="nav-bag">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="black"
            className="bi bi-handbag-fill"
            viewBox="0 0 16 16"
          >
            <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2zM5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0V5z" />
          </svg>
          <span className="bag-quantity">
            <span>{cartTotalQuantity}</span>
          </span>
        </div>
      </Link>
      {auth._id ? (
        <Logout
          onClick={() => {
            dispatch(logoutUser(null));
            toast.warning("Logged out!", { position: "bottom-left" });
          }}
          style={{color: 'black'}}
        >
          <Button size="small" style={{backgroundColor: "#00AEEF", color: 'black'}}>
            Logout
          </Button>
          
        </Logout>
      ) : (
        <AuthLinks>
          <Link to="/login" style={{color: 'black'}}>
            <Button size="small" style={{backgroundColor: "#2156A7", color: "white"}}>
              Login
            </Button>
          </Link>
          <Link to="register" style={{color: 'black'}}>
            <Button size="small" style={{backgroundColor: "#2156A7", color: 'white'}}>
              Register
            </Button>
          </Link>
        </AuthLinks>
      )}
    </nav>
  );
};

export default NavBar;

const AuthLinks = styled.div`
  a {
    &:last-child {
      margin-left: 0.5rem;
    }
  }
`;

const Logout = styled.div`
  color: white;
  cursor: pointer;
`;
