import React from 'react'
import Slider from 'react-slick'
import ava01 from '../../assets/images/ava-1.jpg'
import ava02 from '../../assets/images/ava-2.jpg'
import ava03 from '../../assets/images/ava-3.jpg'

const Testimonials = () => {
   const settings = {
      dots:true,
      infinite:true,
      autoplay:true,
      speed:1000,
      swipeToSlide:true,
      autoplaySpeed:2000,
      slidesToShow:3,

      responsive: [
         {
            breakpoint: 992,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
            },
            breakpoint: 576,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1,
               infinite: true,
               dots: true,
            },
         }
      ]
   }

   return <Slider {...settings}>
      <div className="testimonial py-4 px-3">
         <p>Basecamp Adventure Pakistan crafted an unforgettable journey through the breathtaking landscapes of Northern Pakistan. Our guides were knowledgeable, ensuring a seamless and enriching experience. The serene valleys and majestic peaks were unlike anything we’ve seen. A must for any adventurer!
         </p>

         <div className='d-flex align-items-center gap-4 mt-3'>
            <img src={ava01} className='w-25 h-25 rounded-2' alt="" />
            <div>
               <h6 className='mb-0 mt-3'>John Doe</h6>
               <p>Customer</p>
            </div>
         </div> 
      </div>

      <div className="testimonial py-4 px-3">
         <p>Exploring Northern Pakistan with BaseCamp Adventure was a dream come true! The vibrant local cultures and extraordinary landscapes left us in awe. Every detail was well-planned, and our guides were outstanding. We created lasting memories and highly recommend Basecamp Adventure to anyone seeking thrill and beauty!
         </p>

         <div className='d-flex align-items-center gap-4 mt-3'>
            <img src={ava02} className='w-25 h-25 rounded-2' alt="" />
            <div>
               <h6 className='mb-0 mt-3'>Lia Franklin</h6>
               <p>Customer</p>
            </div>
         </div> 
      </div>

      <div className="testimonial py-4 px-3">
         <p>Our adventure with BaseCamp Adventure Pakistan was simply spectacular! The mesmerizing landscapes, the rich traditions, and the top-notch service made our trip unforgettable. We felt safe and well-cared for every step of the way. Highly recommend for a unique and thrilling experience!
         </p>

         <div className='d-flex align-items-center gap-4 mt-3'>
            <img src={ava03} className='w-25 h-25 rounded-2' alt="" />
            <div>
               <h6 className='mb-0 mt-3'>John Doe</h6>
               <p>Customer</p>
            </div>
         </div> 
      </div>

      <div className="testimonial py-4 px-3">
         <p>Basecamp Adventure delivered a phenomenal tour through the enchanting regions of Northern Pakistan. The blend of thrilling heights, serene beauty, and cultural richness was executed perfectly, creating vibrant memories we’ll cherish forever. A big thumbs up to the whole team for such an exceptional experience!
         </p>

         <div className='d-flex align-items-center gap-4 mt-3'>
            <img src={ava03} className='w-25 h-25 rounded-2' alt="" />
            <div>
               <h6 className='mb-0 mt-3'>John Doe</h6>
               <p>Customer</p>
            </div>
         </div> 
      </div>
   </Slider>
}

export default Testimonials