import React from 'react'
import "./Home.css"
import {Container,Row, Col, Nav} from 'reactstrap'
// import NavBar from "../NavBar"

import heroImg from "../../assets/images/hero-img01.webp"
import heroImg2 from "../../assets/images/hero-img02.webp"
import heroImg03 from '../../assets/images/hero-img03.webp'
// import heroVideo from "../assets/images/hero-video.mp4"
import Subtitle from '../../Shared/Subtitle'
import worldImg from "../../assets/images/world.png"
import experienceImage from "../../assets/images/experience.webp"
// import SearchBar from '../../Shared/SearchBar'
import ServiceList from '../../Services/ServiceList'
import FeaturedToursList from '../FeaturedTours/FeaturedToursList'
import MasonryImagesGallery from '../Image-gallery/MasonryImagesGallery'
import Testimonials from '../Testimonials/Testimonials'
import Newsletter from '../../Shared/Newsletter'
import Contact from './Contact'
import FeaturedBlogsList from '../FeaturedBlogs.jsx/FeaturedBlogsList'

const Home = () => {
  return (
    <>
    {/* <NavBar /> */}
    <section style={{ marginTop: "50px" }}>
      <Container>
        <Row>
          <Col lg="6">
            <div className="hero__content">
              <div className="hero__subtitle d-flex align-items-center">
                <Subtitle subtitle={"Know Before You Go"}/>
                <img src={worldImg} alt="" />
              </div>
              <h1 style={{fontWeight: "bolder"}}>
                Basecamp Adventures Pakistan{" "}
                <span className="highlight">Travel, Enjoy and Live</span>
              </h1>
              <p>
               Embark on an unforgettable journey with Basecamp Adventure Pakistan! Discover the hidden treasures and mesmerizing landscapes of Northern Pakistan, from lush valleys to towering peaks. Join us for a thrilling adventure and create lasting memories in one of the world’s most unspoiled destinations!
              </p>
            </div>
          </Col>
          <Col lg="2">
            <div className="hero__img-box">
              <img src={heroImg} alt="" />
            </div>
          </Col>
          {/* <Col lg="2">
            <div className="hero__img-box video-box mt-4">
              <video src={heroVideo} alt="" autoPlay loop muted />
            </div>
          </Col> */}
          <Col lg='2'>
            <div className="hero__img-box mt-5">
               <img src={heroImg03} alt="" />
            </div>
          </Col>
          <Col lg="2">
            <div className="hero__img-box mt-5">
              <img src={heroImg2} alt="" />
            </div>
          </Col>
          {/* <SearchBar/> */}
        </Row>
      </Container>
    </section>
   
    <section>
      <Container>
        <Row>
          <Col lg="12">
            <h5 className="services__subtitle">What We Serve</h5>
            <h2 className="services__title" style={{fontWeight: "bolder"}}>We Offer Our Best Services</h2>
          </Col>
          </Row>
          <ServiceList/>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="12" className='mb-5'>
            <Subtitle subtitle={"Explore"}/>
            <h2 className="featured__tour-title" style={{fontWeight: "bolder"}}>Our Featured Tours</h2>
          </Col>
          <FeaturedToursList/>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="6">
            <div className="experience__content">
              <Subtitle subtitle={"Experience"}/>
              <h2 style={{fontWeight: "bold"}}>With our all experience <br /> we will serve you</h2>
              <p>Discover the enchanting landscapes and rich cultures of Northern Pakistan with Basecamp Adventure! Our expert guides and carefully crafted experiences ensure unforgettable adventures, from towering peaks to lush valleys.
                <br />
                Join us to create vibrant memories and embrace the extraordinary wonders of this unspoiled region! 
              </p>
            </div>

            <div className="couter__wrapper d-flex align-items-center gap-5">
              <div className="counter__box">
                <span>12k+</span>
                <h6>Successful Trips</h6>
              </div>
              <div className="counter__box">
                <span>2k+</span>
                <h6>Regular Clients</h6>
              </div>
              <div className="counter__box">
                <span>15+</span>
                <h6>Years Experience</h6>
              </div>
            </div>

          </Col>
          <Col lg="6">
            <div className="experience__img">
              <img src={experienceImage} alt="" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <Row>
          <Col lg="12">
            <Subtitle subtitle={"Gallery"}/>
            <h2 className="gallery__title" style={{fontWeight: "bold"}}>
              Visit Our Customers Tour Gallery
            </h2>
          </Col>
          <Col lg="12">
            <MasonryImagesGallery/>
          </Col>
        </Row>
      </Container>
    </section>

    <section>
      <Container>
        <div className="title">
          <Subtitle subtitle={"Featured Blogs"} />
        </div>
        <Row>
      <FeaturedBlogsList lg={4} md={6} sm={6}/>
        </Row>
      </Container>
    </section>
    
    <section>
      <Container>
        <Row>
          <Col lg="12">
            <Subtitle subtitle={"Testimonial"}/>
            <h2 className="testmonials__title" style={{fontWeight: "bold"}}>What our Customers Say about us</h2>
          </Col>
          <Testimonials/>
        </Row>
      </Container>
    </section>
    
    <Contact/>
    <Newsletter/>
    </>
  )
}

export default Home