import axios from "axios";
import { useSelector } from "react-redux";
import { url } from "../slices/api";

const PayButton = ({ cartItems }) => {
  const user = useSelector((state) => state.auth);

  const handleCheckout = () => {
    const formattedCartItems = [];

    cartItems.forEach((cartItem) => {
      const tempData = {
        name: cartItem.name,
        image: cartItem.image,
        desc: cartItem.name,
        price: cartItem.price,
        cartQuantity: cartItem.cartQuantity
      }
      formattedCartItems.push(tempData)
    })

    axios
      .post(`${url}/stripe/create-checkout-session`, {
        cartItems: formattedCartItems,
        userId: user._id,
      })
      .then((response) => {
        if (response.data.url) {
          console.log("here==>", response.data.url)
          window.location.href = response.data.url;
        }
      })
      .catch((err) => console.log(err.message));
  };

  return (
    <>
      <button onClick={() => handleCheckout()}>Check out</button>
    </>
  );
};

export default PayButton;
