import galleryImg01 from "../../assets/images/gallery-01.webp";
import galleryImg02 from "../../assets/images/gallery-02.webp";
import galleryImg03 from "../../assets/images/gallery-03.webp";
import galleryImg04 from "../../assets/images/gallery-04.webp";
import galleryImg05 from "../../assets/images/gallery-05.webp";
import galleryImg06 from "../../assets/images/gallery-06.webp";
import galleryImg07 from "../../assets/images/gallery-07.webp";
import galleryImg08 from "../../assets/images/gallery-02.webp";

const galleryImages = [
  galleryImg01,
  galleryImg02,
  galleryImg03,
  galleryImg04,
  galleryImg05,
  galleryImg06,
  galleryImg07,
  galleryImg08,
];

export default galleryImages;
