import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

// import CartHome from "./components/Home";
import NavBar from "./components/NavBar";
import NotFound from "./components/NotFound";
import Cart from "./components/Cart";

import "react-toastify/dist/ReactToastify.css";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadUser } from "./slices/authSlice";
import CheckoutSuccess from "./components/CheckoutSuccess";

import HomePage from "./components/Pages/Home"

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser(null));
  }, [dispatch]);

  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
       <Route path="/" element={<HomePage />} />
       <Route path="/cart" element={<Cart />} />
       <Route path="/checkout-success" element={<CheckoutSuccess />} />
       <Route path="/register" element={<Register />} />
       <Route path="/login" element={<Login />} />
       <Route path="*" element={<NotFound />} />
      </Routes>
      
    </BrowserRouter>
    
    // <>
    //   <BrowserRouter>
    //     <ToastContainer />
    //     <NavBar />
        
    //     <div className="content-container">
    //       <Routes>
    //         <Route path="/" element={<Home />} />
    //         <Route path="/cart" element={<Cart />} />
    //         <Route path="/checkout-success" element={<CheckoutSuccess />} />
    //         <Route path="/register" element={<Register />} />
    //         <Route path="/login" element={<Login />} />
    //         <Route path="*" element={<NotFound />} />
    //       </Routes>
    //     </div>
    //   </BrowserRouter>
    // </>
  );
}

export default App;
